/* eslint-disable no-unused-vars */
import { TwitterIcon } from '@/components/BrandIcons'
import { markRaw } from 'vue'

export default markRaw([
  {
    name: 'Twitter',
    href: 'https://twitter.com/InnoTekServices',
    icon: TwitterIcon
  },
])
